import styled from 'styled-components';

export const VisitStatusContainer = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    transition: all 0.2s ease;
    gap: 6px;
    cursor: pointer;
    
    ${({ status }) => {
        switch (status?.toLowerCase()) {
            case 'visitada':
                return `
                    background: rgba(34, 197, 94, 0.1);
                    color: #16a34a;
                    border: 1px solid rgba(34, 197, 94, 0.2);
                `;
            case 'aguardando':
            default:
                return `
                    background: rgba(234, 179, 8, 0.1);
                    color: #ca8a04;
                    border: 1px solid rgba(234, 179, 8, 0.2);
                `;
        }
    }}

    &:hover {
        transform: translateY(-1px);
        filter: brightness(0.95);
    }
`; 