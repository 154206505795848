import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Alert, Slide } from '@mui/material';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import logo from '../../../assets/img/logo.png';
import { LoginContainer, LogoContainer, LoginCard, FormContainer } from './styled';
import FullScreenLoading from '../../../components/FullScreenLoading';

const LoginPage = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login, loading } = useContext(AuthContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(formData.email, formData.password);
      navigate('/home');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <LoginContainer>
      {loading && <FullScreenLoading />}
      <LogoContainer>
        <img src={logo} alt="RRAPP Logo" />
      </LogoContainer>
      
      <LoginCard>
        <FormContainer component="form" onSubmit={handleSubmit}>
          {error && (
            <Slide direction="down" in={!!error}>
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            </Slide>
          )}

          <TextField
            fullWidth
            name="email"
            label="E-mail"
            variant="outlined"
            value={formData.email}
            onChange={handleInputChange}
            autoComplete="email"
          />

          <TextField
            fullWidth
            name="password"
            label="Senha"
            type="password"
            variant="outlined"
            value={formData.password}
            onChange={handleInputChange}
            autoComplete="current-password"
          />

          <Button
            fullWidth
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{
              height: '48px',
              textTransform: 'none',
              fontSize: '1rem',
            }}
          >
            {loading ? 'Entrando...' : 'Entrar'}
          </Button>
        </FormContainer>
      </LoginCard>
    </LoginContainer>
  );
};

export default LoginPage; 