import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  TableSortLabel,
} from '@mui/material';

function BasicTable({
  columns,
  data,
  actions = null,
  pagination = true,
  rowsPerPageOptions = [5, 10, 25],
  onRowClick = null,
}) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');

  const handleSort = (column) => {
    const columnDef = columns.find((col) => col.field === column);

    if (columnDef?.sortable) {
      const isAsc = orderBy === column && orderDirection === 'asc';
      setOrderDirection(isAsc ? 'desc' : 'asc');
      setOrderBy(column);
    }
  };

  const getComparator = (a, b, column) => {
    let valueA = a[column];
    let valueB = b[column];

    const columnDef = columns.find((col) => col.field === column);

    if (columnDef && columnDef.renderCell) {
      valueA = a[columnDef.field];
      valueB = b[columnDef.field];
    }

    if (typeof valueA === 'string') {
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
    }

    if (valueA < valueB) return orderDirection === 'asc' ? -1 : 1;
    if (valueA > valueB) return orderDirection === 'asc' ? 1 : -1;
    return 0;
  };

  const sortedData = [...data].sort((a, b) => getComparator(a, b, orderBy));

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const displayedRows = pagination
    ? sortedData.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    : sortedData;

  return (
    <Paper style={{ borderRadius: '10px', overflow: 'hidden' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.field}>
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.field}
                      direction={orderBy === column.field ? orderDirection : 'asc'}
                      onClick={() => handleSort(column.field)}
                    >
                      {column.headerName}
                    </TableSortLabel>
                  ) : (
                    column.headerName
                  )}
                </TableCell>
              ))}
              {actions && <TableCell align="right">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.map((row, index) => (
              <TableRow
                key={index}
                hover={!!onRowClick}
                onClick={() => onRowClick && onRowClick(row)}
                style={{
                  cursor: onRowClick ? 'pointer' : 'default',
                }}
              >
                {columns.map((column) => (
                  <TableCell key={column.field}>
                    {column.renderCell ? column.renderCell(row) : row[column.field]}
                  </TableCell>
                ))}
                {actions && (
                  <TableCell align="right">
                    {actions(row).map((action, actionIndex) => (
                      <React.Fragment key={actionIndex}>{action}</React.Fragment>
                    ))}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <Box display="flex" justifyContent="center" my={2} pb={2}>
          <Pagination
            count={Math.ceil(data.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Paper>
  );
}

export default BasicTable;
