import styled from 'styled-components';
import { MapPin } from '@phosphor-icons/react';
import { Button } from '@mui/material';

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const ImageContainer = styled.div`
    width: 100%;
    height: 200px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

export const PropertyImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const NoImageContainer = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.palette.background.input};
    border-radius: 8px;
    gap: 8px;
    color: ${props => props.theme.palette.text.secondary};
`;

export const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const InfoRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const Label = styled.span`
    font-size: 14px;
    color: ${props => props.theme.palette.text.secondary};
    font-weight: 500;
`;

export const Value = styled.span`
    font-size: 16px;
    color: ${props => props.theme.palette.text.primary};
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
`;

export const LocationButton = styled(Button)`
    && {
        margin-left: auto;
        background-color: ${props => props.theme.palette.primary.main};
        color: white;
        text-transform: none;
        padding: 6px 16px;
        font-size: 14px;
        border-radius: 8px;
        transition: all 0.3s ease;

        &:hover {
            background-color: ${props => props.theme.palette.primary.dark};
            transform: translateY(-1px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        }

        .MuiButton-startIcon {
            margin-right: 8px;
        }
    }
`;

export const MarkerIcon = styled(MapPin).attrs({
    size: 20
})`
    color: ${props => props.theme.palette.primary.main};
`; 