import React, { createContext, useContext, useState } from 'react';
import { ReadAdmin } from '../../service/back-for-front';

const PropertiesContext = createContext({});

export const PropertiesProvider = ({ children }) => {
    const [properties, setProperties] = useState([]);
    const [processedData, setProcessedData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [totals, setTotals] = useState({
        total: 0,
        reprovadas: 0,
        visitadas: 0,
        aguardando: 0,
        aprovadas: 0,
        inativas: 0
    });

    const calculateTotals = (properties) => {
        // console.log('📊 Calculando totais para:', properties);

        if (!properties || !Array.isArray(properties)) return {
            total: 0,
            reprovadas: 0,
            visitadas: 0,
            aguardando: 0,
            aprovadas: 0,
            inativas: 0
        };

        const totals = {
            total: properties.length,
            reprovadas: properties.filter(prop =>
                prop.status?.toLowerCase() === 'reprovada' ||
                prop.status?.toLowerCase() === 'reprovado'
            ).length,
            visitadas: properties.filter(prop =>
                prop.status_visit?.toLowerCase() === 'visitado' ||
                prop.status_visit?.toLowerCase() === 'ativo'
            ).length,
            aguardando: properties.filter(prop =>
                prop.status?.toLowerCase() === 'aguardando'
            ).length,
            aprovadas: properties.filter(prop =>
                prop.status?.toLowerCase() === 'ativo' ||
                prop.status?.toLowerCase() === 'aprovada' ||
                prop.status?.toLowerCase() === 'aprovado'
            ).length,
            inativas: properties.filter(prop =>
                prop.status?.toLowerCase() === 'inativo' ||
                prop.status?.toLowerCase() === 'inativa'
            ).length
        };

        // console.log('📊 Totais calculados:', totals);
        return totals;
    };

    const processData = (data) => {
        if (!data || !Array.isArray(data)) return [];

        return data.flatMap(user => {
            if (!user.data?.properties) return [];

            return user.data.properties.map(property => ({
                ...property,
                proprietario: user.name || property.user?.name || 'Sem proprietário',
                userEmail: user.email || property.user?.email,
                name: property.name || '',
                property_number: property.property_number || '',
                area: property.area || '',
                municipality: property.municipality || '',
                line: property.line || '',
                patrol: property.patrol || null,
                status: property.status || 'Não definido',
                status_visit: property.status_visit || 'inativo'
            }));
        });
    };

    const fetchProperties = async () => {
        try {
            // console.log('🔄 Iniciando busca de dados...');
            const response = await ReadAdmin();
            // console.log('🔄 Resposta da API:', response);

            if (response?.data) {
                setProperties(response.data);
                const processed = processData(response.data);
                setProcessedData(processed);
                const calculatedTotals = calculateTotals(processed);
                setTotals(calculatedTotals);
                return response.data;
            }
        } catch (error) {
            console.error('Erro ao buscar propriedades:', error);
        }
    };

    const filterProperties = (filters) => {
        console.log('🔍 Iniciando filtragem no contexto');
        console.log('Dados processados:', processedData);
        console.log('Filtros recebidos:', filters);

        const filtered = processedData.filter(item => {
            // Se tem filtro de usuário e não corresponde, retorna false
            if (filters.selectedUser && item.user?.username !== filters.selectedUser.code) {
                return false;
            }

            // Se tem filtro de propriedade e não corresponde, retorna false
            if (filters.selectedPropriet && item.name !== filters.selectedPropriet.code) {
                return false;
            }

            // Se tem filtro de batalhão e não corresponde, retorna false
            if (filters.selectedPatrol && item.patrol?.name !== filters.selectedPatrol.code) {
                return false;
            }

            // Se tem filtro de status e não corresponde, retorna false
            if (filters.status && item.status?.toLowerCase() !== filters.status.toLowerCase()) {
                return false;
            }

            // Se tem filtro de status de visita e não corresponde, retorna false
            if (filters.statusVisit && item.status_visit?.toLowerCase() !== filters.statusVisit.toLowerCase()) {
                return false;
            }

            // Se tem filtro de área e não corresponde, retorna false
            if (filters.area && !item.area?.toLowerCase().includes(filters.area.toLowerCase())) {
                return false;
            }

            // Se tem filtro de município e não corresponde, retorna false
            if (filters.municipality && !item.municipality?.toLowerCase().includes(filters.municipality.toLowerCase())) {
                return false;
            }

            // Se tem filtro de linha e não corresponde, retorna false
            if (filters.line && !item.line?.toLowerCase().includes(filters.line.toLowerCase())) {
                return false;
            }

            // Se passou por todos os filtros, retorna true
            return true;
        });

        console.log('✅ Resultado da filtragem:', filtered);
        setFilteredData(filtered);
        const newTotals = calculateTotals(filtered);
        setTotals(newTotals);
    };

    const clearFilters = () => {
        setFilteredData(processedData);
        const newTotals = calculateTotals(processedData);
        setTotals(newTotals);
    };

    return (
        <PropertiesContext.Provider value={{
            properties,
            processedData,
            filteredData,
            totals,
            fetchProperties,
            filterProperties,
            clearFilters,
            calculateTotals
        }}>
            {children}
        </PropertiesContext.Provider>
    );
};

export const useProperties = () => {
    const context = useContext(PropertiesContext);
    if (!context) {
        throw new Error('useProperties must be used within a PropertiesProvider');
    }
    return context;
}; 