import styled from 'styled-components';

export const ActionContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 24px;
`;

export const ActionButton = styled.button`
    flex: 1;
    padding: 12px;
    border-radius: 4px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;

    ${props => props.variant === 'approve' && `
        background-color: ${props.theme.palette.success.main};
        color: white;

        &:hover {
            background-color: ${props.theme.palette.success.dark};
        }
    `}

    ${props => props.variant === 'reject' && `
        background-color: ${props.theme.palette.error.main};
        color: white;

        &:hover {
            background-color: ${props.theme.palette.error.dark};
        }
    `}
`;

export const PropertyInfo = styled.div`
    padding: 16px;
    background-color: ${props => props.theme.palette.background.paper};
    border-radius: 4px;
    margin-bottom: 16px;
`;

export const PropertyName = styled.h3`
    margin: 0;
    color: ${props => props.theme.palette.text.primary};
    font-size: 18px;
    margin-bottom: 8px;
`;

export const PropertyOwner = styled.p`
    margin: 0;
    color: ${props => props.theme.palette.text.secondary};
    font-size: 14px;
`; 