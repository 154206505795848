import { GET, POST, PUT } from "./api";

export const Create = async (params) => {
  return await POST(`/properties`, params, true);
}

export const ReadProperties = async () => {
  return await GET('/properties/with-photos');
};

export const ReadProperty = async (id) => {
  return await GET(`/properties/${id}`);
};

export const UpdateStatus = async (id, data) => {
  const payload = {
    data: {
      ...data.data
    }
  };
  return await PUT(`/properties/${id}`, payload, true);
}