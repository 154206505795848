import React from 'react';
import { Outlet } from 'react-router-dom';
import { BodyPage } from '../pages/styledGlobal';
import Header from './Header';

const Layout = () => {
    return (
        <BodyPage style={{ display: 'flex', flexDirection: "column", backgroundColor: "#f2f3f4" }}>
            <Header />
            <div style={{ padding: '20px' }}>
                <Outlet />
            </div>
        </BodyPage>
    );
}

export default Layout;
