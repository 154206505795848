const ERROR_MESSAGES = {
    'Invalid identifier or password': 'E-mail ou senha inválidos',
    'Missing or invalid credentials': 'Credenciais ausentes ou inválidas',
    'Email or Username are already taken': 'Este e-mail já está em uso',
    'Email is already taken': 'Este e-mail já está em uso',
    'default': 'Ocorreu um erro. Por favor, tente novamente.'
};

export const handleStrapiError = (error) => {
    // Verifica se é um erro do Strapi
    if (error?.error?.message) {
        const strapiMessage = error.error.message;
        return ERROR_MESSAGES[strapiMessage] || ERROR_MESSAGES.default;
    }

    // Se for um erro genérico
    if (error?.message) {
        return ERROR_MESSAGES[error.message] || error.message;
    }

    return ERROR_MESSAGES.default;
}; 