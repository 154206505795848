import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { 
    MapWrapper, 
    MapContainer as StyledMapContainer, 
    MapHeader, 
    MapTitle,
    MapControlsContainer,
    ControlButton,
    FullscreenMapContainer
} from './styled';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { 
    MagnifyingGlassPlus, 
    MagnifyingGlassMinus, 
    CornersOut,
    MapTrifold
} from '@phosphor-icons/react';

// Corrigir o problema dos ícones do Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Renomeando o componente para MapControlsComponent
function MapControlsComponent({ map, handleZoomIn, handleZoomOut, toggleFullscreen, toggleMapType, isSatellite }) {
    return (
        <MapControlsContainer>
            <ControlButton onClick={handleZoomIn} title="Aumentar zoom">
                <MagnifyingGlassPlus size={20} weight="duotone" />
            </ControlButton>
            <ControlButton onClick={handleZoomOut} title="Diminuir zoom">
                <MagnifyingGlassMinus size={20} weight="duotone" />
            </ControlButton>
            <ControlButton 
                onClick={toggleMapType} 
                title="Alternar modo satélite"
                active={isSatellite}
            >
                <MapTrifold size={20} weight="duotone" />
            </ControlButton>
            <ControlButton onClick={toggleFullscreen} title="Tela cheia">
                <CornersOut size={20} weight="duotone" />
            </ControlButton>
        </MapControlsContainer>
    );
}

// Componente para atualizar a visualização do mapa
function MapUpdater({ selectedProduct }) {
    const map = useMap();

    useEffect(() => {
        if (selectedProduct?.lat && selectedProduct?.long) {
            const position = [parseFloat(selectedProduct.lat), parseFloat(selectedProduct.long)];
            map.setView(position, 15);
        }
    }, [selectedProduct, map]);

    return null;
}

const ModernMap = ({ selectedProduct, setSelectedProduct, properties }) => {
    const theme = useTheme();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [map, setMap] = useState(null);
    const [isSatellite, setIsSatellite] = useState(false);

    const handleZoomIn = () => {
        if (map) map.zoomIn();
    };

    const handleZoomOut = () => {
        if (map) map.zoomOut();
    };

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    const toggleMapType = () => {
        setIsSatellite(!isSatellite);
    };

    const handleMarkerClick = (property) => {
        setSelectedProduct(property);
    };

    const getMarkerIcon = (property) => {
        const isSelected = selectedProduct?.id === property.id;
        const size = isSelected ? 12 : 8;
        const color = (() => {
            switch (property.status?.toLowerCase()) {
                case 'ativo':
                case 'aprovada':
                case 'aprovado':
                    return '#66bb6a';
                case 'reprovada':
                case 'reprovado':
                    return '#f44336';
                case 'aguardando':
                    return '#ffa726';
                case 'inativo':
                case 'inativa':
                    return '#9e9e9e';
                default:
                    return theme.palette.primary.main;
            }
        })();

        return L.divIcon({
            html: `<div style="
                background-color: ${color};
                width: ${size * 2}px;
                height: ${size * 2}px;
                border-radius: 50%;
                border: 2px solid white;
                box-shadow: 0 0 4px rgba(0,0,0,0.4);
            "></div>`,
            className: '',
            iconSize: [size * 2, size * 2],
        });
    };

    const MapContent = () => (
        <>
            <MapHeader>
                <MapTitle>Localização das Propriedades</MapTitle>
            </MapHeader>
            <StyledMapContainer isFullscreen={isFullscreen}>
                <MapControlsComponent 
                    map={map}
                    handleZoomIn={handleZoomIn}
                    handleZoomOut={handleZoomOut}
                    toggleFullscreen={toggleFullscreen}
                    toggleMapType={toggleMapType}
                    isSatellite={isSatellite}
                />
                
                <MapContainer
                    center={[-15.793889, -47.882778]}
                    zoom={10}
                    style={{ width: '100%', height: '100%' }}
                    whenCreated={setMap}
                    zoomControl={false}
                    attributionControl={true}
                >
                    <TileLayer
                        url={isSatellite 
                            ? 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                            : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        }
                        attribution={isSatellite 
                            ? 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                            : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        }
                    />
                    <MapUpdater selectedProduct={selectedProduct} />
                    
                    {properties?.map((property, index) => (
                        <Marker
                            key={index}
                            position={[parseFloat(property.lat), parseFloat(property.long)]}
                            icon={getMarkerIcon(property)}
                            eventHandlers={{
                                click: () => handleMarkerClick(property)
                            }}
                        >
                            <Popup>
                                <strong>{property.name}</strong>
                                <br />
                                Status: {property.status}
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </StyledMapContainer>
        </>
    );

    if (isFullscreen) {
        return (
            <FullscreenMapContainer>
                <MapContent />
            </FullscreenMapContainer>
        );
    }

    return (
        <MapWrapper>
            <MapContent />
        </MapWrapper>
    );
};

export default ModernMap; 