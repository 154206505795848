import React, { useContext } from 'react';
import Wrapper from '../Wrapper';
import { 
    ActionContainer,
    ActionButton,
    PropertyInfo,
    PropertyName,
    PropertyOwner
} from './styled';
import { AuthContext } from '../../../context/contextGlobal/authContext';

const ChooseActionModal = () => {
    const { modal, setModal } = useContext(AuthContext);
    const property = modal.data;

    const handleAction = (actionType) => {
        setModal({
            type: actionType,
            data: property
        });
    };

    return (
        <Wrapper title="Escolha uma ação" width="400px">
            <PropertyInfo>
                <PropertyName>{property.name}</PropertyName>
                <PropertyOwner>Proprietário: {property.proprietario}</PropertyOwner>
            </PropertyInfo>

            <ActionContainer>
                <ActionButton 
                    onClick={() => handleAction('approveProperty')}
                    variant="approve"
                >
                    Aprovar
                </ActionButton>
                <ActionButton 
                    onClick={() => handleAction('rejectProperty')}
                    variant="reject"
                >
                    Reprovar
                </ActionButton>
                {property.status?.toLowerCase() !== 'inativa' && (
                    <ActionButton 
                        onClick={() => handleAction('inactivateProperty')}
                        variant="inactivate"
                    >
                        Inativar
                    </ActionButton>
                )}
            </ActionContainer>
        </Wrapper>
    );
};

export default ChooseActionModal; 