import React from 'react';
import { 
    CardContainer, 
    CardTitle, 
    CardValue,
    CardContent,
    IconContainer
} from './styled';
import { 
    ChartLineUp, 
    CheckCircle, 
    XCircle, 
    Eye, 
    Clock, 
    ProhibitInset 
} from '@phosphor-icons/react';

const getIconByTitle = (title) => {
    switch (title) {
        case 'Total':
            return <ChartLineUp size={24} weight="duotone" />;
        case 'Total Reprovadas':
            return <XCircle size={24} weight="duotone" />;
        case 'Total Visitadas':
            return <Eye size={24} weight="duotone" />;
        case 'Total Aguardando':
            return <Clock size={24} weight="duotone" />;
        case 'Total Aprovadas':
            return <CheckCircle size={24} weight="duotone" />;
        case 'Total Inativas':
            return <ProhibitInset size={24} weight="duotone" />;
        default:
            return <ChartLineUp size={24} weight="duotone" />;
    }
};

const StatisticCard = ({ title, value }) => {
    return (
        <CardContainer>
            <CardContent>
                <div>
                    <CardTitle>{title}</CardTitle>
                    <CardValue>{value}</CardValue>
                </div>
                <IconContainer>
                    {getIconByTitle(title)}
                </IconContainer>
            </CardContent>
        </CardContainer>
    );
};

export default StatisticCard; 