import React, { useContext, useState } from 'react';
import Wrapper from '../Wrapper';
import { 
    FormContainer,
    InputGroup,
    Label,
    Select,
    Input,
    ButtonContainer,
    ApproveButton,
    CancelButton
} from './styled';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import { useProperties } from '../../../context/PropertiesContext';
import { UpdateStatus } from '../../../service/property';
import { toast } from 'react-toastify';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ApprovePropertyModal = () => {
    const { modal, setModal, patrols } = useContext(AuthContext);
    const { fetchProperties } = useProperties();
    const property = modal.data;

    const [selectedPatrol, setSelectedPatrol] = useState('');
    const [propertyNumber, setPropertyNumber] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleApprove = () => {
        if (!selectedPatrol) {
            toast.error('Selecione um batalhão');
            return;
        }

        if (!propertyNumber) {
            toast.error('Digite o número da propriedade');
            return;
        }

        setConfirmDialogOpen(true);
    };

    const handleConfirmApprove = async () => {
        try {
            const updateData = {
                data: {
                    status: 'aprovada',
                    patrol: {
                        connect: [{ id: selectedPatrol }]
                    },
                    property_number: propertyNumber
                }
            };

            console.log('Dados sendo enviados:', updateData);
            await UpdateStatus(property.id, updateData);
            await fetchProperties();
            toast.success('Propriedade aprovada com sucesso!');
            setConfirmDialogOpen(false);
            setModal(null);
        } catch (error) {
            console.error('Erro ao aprovar propriedade:', error);
            toast.error('Erro ao aprovar propriedade');
        }
    };

    const handleCancel = () => {
        setModal(null);
    };

    return (
        <>
            <Wrapper title="Aprovar Propriedade" width="400px">
                <FormContainer>
                    <InputGroup>
                        <Label>Batalhão</Label>
                        <Select
                            value={selectedPatrol}
                            onChange={(e) => setSelectedPatrol(e.target.value)}
                        >
                            <option value="">Selecione um batalhão</option>
                            {patrols.map(patrol => (
                                <option key={patrol.id} value={patrol.id}>
                                    {patrol.name}
                                </option>
                            ))}
                        </Select>
                    </InputGroup>

                    <InputGroup>
                        <Label>Número da Propriedade</Label>
                        <Input
                            type="text"
                            value={propertyNumber}
                            onChange={(e) => setPropertyNumber(e.target.value)}
                            placeholder="Digite o número da propriedade"
                        />
                    </InputGroup>

                    <ButtonContainer>
                        <CancelButton onClick={handleCancel}>
                            Cancelar
                        </CancelButton>
                        <ApproveButton onClick={handleApprove}>
                            Aprovar
                        </ApproveButton>
                    </ButtonContainer>
                </FormContainer>
            </Wrapper>

            <Dialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
            >
                <DialogTitle>Confirmar Aprovação</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tem certeza que deseja aprovar esta propriedade?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
                        Não
                    </Button>
                    <Button onClick={handleConfirmApprove} color="success" variant="contained">
                        Sim, aprovar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ApprovePropertyModal; 