import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, TextField, CircularProgress } from '@mui/material';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import BasicTable from '../../../components/Dashboard/BasicTable';
import { Button } from 'primereact/button';

export default function ActiveUsersList() {
  const { users, dados } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (users.length > 0 && dados.length > 0) {
      setLoading(false);
    }
  }, [users, dados]);

  const rows = dados
    .map(row => {
      const user = users.find(u => u.uuid === row.userUuid);
      return {
        ...row,
        fullName: user ? user.fullName : null,
        email: user ? user.email : null,
        status: row.status && row.status.toLowerCase() === 'ativo' ? 'Ativo' : 'Inativo',
      };
    })
    .filter(row => row.fullName && row.email);

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = rows.filter((row) => {
    return Object.values(row).some(value => {
      if (value !== null && value !== undefined) {
        return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    });
  });

  const columns = [
    { field: 'fullName', headerName: 'Nome' },
    { field: 'email', headerName: 'Email' },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (rowData) => (
        <Button
          severity={rowData.status === 'Ativo' ? 'success' : 'secondary'}
          onClick={() => {}}
        >
          {rowData.status}
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ padding: 3, paddingBottom: 20 }}>
      <Typography variant="h4" gutterBottom>
        Total de usuários ativos e inativos
      </Typography>
      <TextField
        label="Pesquisar"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <BasicTable
          columns={columns}
          data={filteredUsers}
          pagination={true}
        />
      )}
    </Box>
  );
}
