import { GET } from "./api";

export const Read = async (id) => {
  return await GET(`/back-for-front/${id}`, true);
}

export const ReadAdmin = async () => {
  return await GET(`/properties/bff-admin`, true);
}

export const ReadByUser = async (id) => {
  return await GET(`/properties/bff-admin?userId=${id}`, true);
}
