import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from "styled-components";
import { theme } from './theme-color';

export function ThemedComponent({ children }) {
    return (
        <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </MuiThemeProvider>
    );
}