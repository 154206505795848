import React, { useContext, useState } from 'react';
import Wrapper from '../Wrapper';
import { 
    FormContainer,
    InputGroup,
    Label,
    Select,
    TextArea,
    ButtonContainer,
    InactivateButton,
    CancelButton
} from './styled';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import { useProperties } from '../../../context/PropertiesContext';
import { UpdateStatus } from '../../../service/property';
import { toast } from 'react-toastify';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const InactivatePropertyModal = () => {
    const { modal, setModal, patrols } = useContext(AuthContext);
    const { fetchProperties } = useProperties();
    const property = modal.data;

    const [selectedPatrol, setSelectedPatrol] = useState('');
    const [observation, setObservation] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleInactivate = () => {
        if (!selectedPatrol) {
            toast.error('Selecione um batalhão');
            return;
        }

        if (!observation.trim()) {
            toast.error('Digite uma observação');
            return;
        }

        setConfirmDialogOpen(true);
    };

    const handleConfirmInactivate = async () => {
        try {
            const updateData = {
                data: {
                    status: 'inativa',
                    patrol: {
                        connect: [{ id: selectedPatrol }]
                    },
                    observacao: observation
                }
            };

            console.log('Dados sendo enviados:', updateData);
            await UpdateStatus(property.id, updateData);
            await fetchProperties();
            toast.success('Propriedade inativada com sucesso!');
            setConfirmDialogOpen(false);
            setModal(null);
        } catch (error) {
            console.error('Erro ao inativar propriedade:', error);
            toast.error('Erro ao inativar propriedade');
        }
    };

    const handleCancel = () => {
        setModal(null);
    };

    return (
        <>
            <Wrapper title="Inativar Propriedade" width="400px">
                <FormContainer>
                    <InputGroup>
                        <Label>Batalhão</Label>
                        <Select
                            value={selectedPatrol}
                            onChange={(e) => setSelectedPatrol(e.target.value)}
                        >
                            <option value="">Selecione um batalhão</option>
                            {patrols.map(patrol => (
                                <option key={patrol.id} value={patrol.id}>
                                    {patrol.name}
                                </option>
                            ))}
                        </Select>
                    </InputGroup>

                    <InputGroup>
                        <Label>Observação</Label>
                        <TextArea
                            value={observation}
                            onChange={(e) => setObservation(e.target.value)}
                            placeholder="Digite o motivo da inativação"
                            rows={4}
                        />
                    </InputGroup>

                    <ButtonContainer>
                        <CancelButton onClick={handleCancel}>
                            Cancelar
                        </CancelButton>
                        <InactivateButton onClick={handleInactivate}>
                            Inativar
                        </InactivateButton>
                    </ButtonContainer>
                </FormContainer>
            </Wrapper>

            <Dialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
            >
                <DialogTitle>Confirmar Inativação</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tem certeza que deseja inativar esta propriedade?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
                        Não
                    </Button>
                    <Button onClick={handleConfirmInactivate} color="warning" variant="contained">
                        Sim, inativar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InactivatePropertyModal; 