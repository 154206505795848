import React from 'react';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 99999,
  animation: `${fadeIn} 0.3s ease-in-out`,
}));

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const CustomSpinner = styled(Box)(({ theme }) => ({
  width: '50px',
  height: '50px',
  border: `4px solid ${theme.palette.primary.light}`,
  borderTop: `4px solid ${theme.palette.primary.main}`,
  borderRadius: '50%',
  animation: `${spinAnimation} 1s linear infinite`,
  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
}));

const LoadingText = styled(Box)(({ theme }) => ({
  position: 'absolute',
  marginTop: '100px',
  color: theme.palette.primary.main,
  fontSize: '1.2rem',
  fontWeight: 500,
}));

const FullScreenLoading = () => (
  <LoadingOverlay>
    <CustomSpinner />
    <LoadingText>Carregando...</LoadingText>
  </LoadingOverlay>
);

export default FullScreenLoading; 