import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
    const [filters, setFilters] = useState({
        selectedUser: null,
        selectedPropriet: null,
        selectedPatrol: null,
        status: '',
        statusVisit: '',
        area: '',
        municipality: '',
        line: ''
    });

    const [filteredData, setFilteredData] = useState([]);

    const updateFilters = (newFilters) => {
        console.log('🔄 Atualizando filtros:', { atual: filters, novo: newFilters });
        setFilters(prev => ({
            ...prev,
            ...newFilters
        }));
    };

    const applyFilters = (data) => {
        console.log('📊 Dados recebidos para filtrar:', data);
        
        // Extrair todas as propriedades
        let allProperties = data.flatMap(user => {
            const properties = user.data?.properties?.map(prop => ({
                ...prop,
                proprietario: user.name,
                userEmail: user.email
            })) || [];
            return properties;
        });

        console.log('🏘️ Total de propriedades antes dos filtros:', allProperties.length);

        // Aplicar filtros
        let result = allProperties;

        if (filters.selectedUser?.code) {
            result = result.filter(item => 
                item.user?.id === filters.selectedUser.code
            );
            console.log('👤 Após filtro de usuário:', result.length);
        }

        if (filters.selectedPropriet?.code) {
            result = result.filter(item => 
                item.id === filters.selectedPropriet.code
            );
            console.log('🏠 Após filtro de propriedade:', result);
        }

        if (filters.selectedPatrol?.code) {
            result = result.filter(item => 
                item.patrol?.id === filters.selectedPatrol.code
            );
            console.log('👮 Após filtro de batalhão:', result.length);
        }

        if (filters.status) {
            result = result.filter(item => 
                item.status?.toLowerCase() === filters.status.toLowerCase()
            );
            console.log('📊 Após filtro de status:', result.length);
        }

        if (filters.statusVisit) {
            result = result.filter(item => 
                item.status_visit?.toLowerCase() === filters.statusVisit.toLowerCase()
            );
            console.log('👁️ Após filtro de status de visita:', result.length);
        }

        if (filters.area) {
            result = result.filter(item => 
                item.area?.toString().toLowerCase().includes(filters.area.toLowerCase())
            );
            console.log('📏 Após filtro de área:', result.length);
        }

        if (filters.municipality) {
            result = result.filter(item => 
                item.municipality?.toLowerCase().includes(filters.municipality.toLowerCase())
            );
            console.log('🏙️ Após filtro de município:', result.length);
        }

        if (filters.line) {
            result = result.filter(item => 
                item.line?.toLowerCase().includes(filters.line.toLowerCase())
            );
            console.log('〽️ Após filtro de linha:', result.length);
        }

        console.log('✅ Resultado final da filtragem:', result);
        setFilteredData(result);
        return result;
    };

    const clearFilters = () => {
        console.log('🧹 Limpando todos os filtros');
        setFilters({
            selectedUser: null,
            selectedPropriet: null,
            selectedPatrol: null,
            status: '',
            statusVisit: '',
            area: '',
            municipality: '',
            line: ''
        });
        setFilteredData([]);
    };

    return (
        <FilterContext.Provider value={{
            filters,
            updateFilters,
            applyFilters,
            clearFilters,
            filteredData,
            setFilteredData
        }}>
            {children}
        </FilterContext.Provider>
    );
};

export const useFilter = () => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error('useFilter must be used within a FilterProvider');
    }
    return context;
}; 