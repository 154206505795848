import React, { useContext } from 'react';
import { Button } from './styled';
import { AuthContext } from '../../../context/contextGlobal/authContext';

const StatusButton = ({ status, rowData }) => {
    const { setModal } = useContext(AuthContext);

    const handleClick = (e) => {
        e.stopPropagation(); // Evita que o clique propague para a linha da tabela
        
        if (['aprovada', 'aguardando', 'inativa'].includes(status?.toLowerCase())) {
            setModal({
                type: 'chooseAction',
                data: rowData
            });
        }
    };

    return (
        <Button 
            status={status?.toLowerCase()} 
            onClick={handleClick}
            clickable={['aprovada', 'aguardando', 'inativa'].includes(status?.toLowerCase())}
        >
            {status || 'Não definido'}
        </Button>
    );
};

export default StatusButton;