import styled from 'styled-components';

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const Label = styled.label`
    color: ${props => props.theme.palette.text.primary};
    font-size: 14px;
    font-weight: 500;
`;

export const Select = styled.select`
    padding: 10px;
    border: 1px solid ${props => props.theme.palette.primary.pale};
    border-radius: 4px;
    font-size: 14px;
    color: ${props => props.theme.palette.text.primary};
    background-color: white;
    outline: none;
    transition: all 0.2s ease;

    &:focus {
        border-color: ${props => props.theme.palette.primary.main};
        box-shadow: 0 0 0 2px ${props => `${props.theme.palette.primary.main}20`};
    }
`;

export const TextArea = styled.textarea`
    padding: 10px;
    border: 1px solid ${props => props.theme.palette.primary.pale};
    border-radius: 4px;
    font-size: 14px;
    color: ${props => props.theme.palette.text.primary};
    resize: vertical;
    min-height: 100px;
    outline: none;
    transition: all 0.2s ease;

    &:focus {
        border-color: ${props => props.theme.palette.primary.main};
        box-shadow: 0 0 0 2px ${props => `${props.theme.palette.primary.main}20`};
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 20px;
`;

export const RejectButton = styled.button`
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: ${props => props.theme.palette.error.main};
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        background-color: ${props => props.theme.palette.error.dark};
    }
`;

export const CancelButton = styled.button`
    flex: 1;
    padding: 10px;
    border: 1px solid ${props => props.theme.palette.primary.main};
    border-radius: 4px;
    background-color: transparent;
    color: ${props => props.theme.palette.primary.main};
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        background-color: ${props => props.theme.palette.primary.pale};
    }
`; 