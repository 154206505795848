import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px 0px;
    flex-wrap: wrap;
`;

export const PageTitle = styled.h1`
    font-size: 28px;
    font-weight: 600;
    color: ${props => props.theme.palette.primary.main};
    text-align: center;
    margin: 0;
    padding: 20px 0;
    position: relative;
    display: inline-block;
    width: 100%;

    &:after {
        content: '';
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 160px;
        height: 2px;
        background:${props => props.theme.palette.primary.main};
        opacity: 0.3;
    }
`;

export const ContentContainer = styled.div`
    padding: 0 20px;
    /* max-width: 1600px; */
    margin: 0 auto;
    width: 100%;
`;