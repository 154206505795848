import styled from 'styled-components';

export const CompanyDataContainer = styled.div`
    width: 100%;
`;

export const WrapperTitleAndCloseIcon = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

export const Title = styled.h2`
    color: ${props => props.theme.palette.text.primary};
    font-size: 20px;
    font-weight: 600;
    margin: 0;
`;

export const CloseContainer = styled.div`
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;

    &:hover {
        opacity: 0.8;
    }
`;