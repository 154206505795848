import './App.css';
import "primereact/resources/themes/saga-green/theme.css";
import Navigator from './navigation/navigation';
import { AuthProvider } from './context/contextGlobal/authContext';
import { FilterProvider } from './context/FilterContext';
import { PropertiesProvider } from './context/PropertiesContext';
import { ThemedComponent } from './ui/theme';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ModalCore from './components/Modal/Core';

function App() {
    return (
        <ThemedComponent>
            <PropertiesProvider>
                <FilterProvider>
                    <AuthProvider>
                        <Navigator />
                        <ModalCore />
                        <ToastContainer />
                    </AuthProvider>
                </FilterProvider>
            </PropertiesProvider>
        </ThemedComponent>
    );
}

export default App;
