import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: 16px;
  color: ${props => props.theme.palette.text.secondary};
  
  span {
    margin-top: 8px;
    font-size: 14px;
  }
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  padding: 32px;
  text-align: center;
  color: ${props => props.theme.palette.text.secondary};

  svg {
    color: ${props => props.theme.palette.primary.main};
    margin-bottom: 16px;
    opacity: 0.8;
  }

  h3 {
    margin: 0;
    margin-bottom: 8px;
    color: ${props => props.theme.palette.text.primary};
    font-weight: 600;
  }

  p {
    margin: 0;
    font-size: 14px;
    max-width: 300px;
    line-height: 1.5;
  }
`; 