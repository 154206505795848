import React from 'react';
import { Chart } from "react-google-charts";
import { Box, Typography, CircularProgress } from '@mui/material';
import { ChartPie } from '@phosphor-icons/react';
import { EmptyStateContainer, LoadingContainer } from './styled';

const DonutChart = ({ data, title, loading }) => {
  const options = {
    title: title,
    pieHole: 0.4,
    is3D: false,
    legend: { position: 'bottom' },
    colors: ['#A3A65B', '#51594C', '#D5D96C', '#D9D5A0'], // Usando cores do tema
  };

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress size={40} thickness={4} />
        <span>Carregando dados...</span>
      </LoadingContainer>
    );
  }

  if (!data || data.length <= 1) {
    return (
      <EmptyStateContainer>
        <ChartPie size={48} weight="duotone" />
        <h3>Sem dados disponíveis</h3>
        <p>Não há dados suficientes para gerar o gráfico.</p>
      </EmptyStateContainer>
    );
  }

  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
};

export default DonutChart; 