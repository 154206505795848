import React, { createContext, useState, useEffect } from "react";
import { ReadObject, SaveObject } from "../../service/storage";
import { DoLogin } from "../../service/authentication";
import { handleStrapiError } from '../../utils/errorHandler';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [propriets, setPropriets] = useState(0);
    const [totalPropriedadesReprovadas, setTotalPropriedadesReprovadas] = useState(0);
    const [totalPropriedadesVisitadas, setTotalPropriedadesVisitadas] = useState(0);
    const [totalPropriedadesAguardando, setTotalPropriedadesAguardando] = useState(0);
    const [totalPropriedadesAprovadas, setTotalPropriedadesAprovadas] = useState(0);
    const [totalPropriedadesInativas, setTotalPropriedadesInativas] = useState(0);
    const [data, setData] = useState([]);
    const [patrols, setPatrols] = useState([]);
    const [userProperties, setUserProperties] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: -15.793889, lng: -47.882778 });
    const [modal, setModal] = useState(null);

    useEffect(() => {
        const recoveredUser = ReadObject("user");
        const token = localStorage.getItem("@token");

        if (recoveredUser && token) {
            setUser(recoveredUser);
        }

        setLoading(false);
    }, []);

    const login = async (identifier, password) => {
        try {
            const response = await DoLogin({ identifier, password });

            if (response?.jwt) {
                SaveObject("user", response.user);
                localStorage.setItem("@token", response.jwt);
                setUser(response.user);
            } else if (response?.error) {
                throw response;
            } else {
                throw new Error("Erro inesperado ao fazer login");
            }
        } catch (error) {
            const translatedMessage = handleStrapiError(error);
            throw new Error(translatedMessage);
        }
    };

    return (
        <AuthContext.Provider value={{
            authenticated: !!user,
            user,
            loading,
            login,
            propriets,
            setPropriets,
            data,
            setData,
            userProperties,
            setUserProperties,
            selectedProduct,
            setSelectedProduct,
            mapCenter,
            setMapCenter,
            totalPropriedadesReprovadas,
            setTotalPropriedadesReprovadas,
            totalPropriedadesVisitadas,
            setTotalPropriedadesVisitadas,
            totalPropriedadesAguardando,
            setTotalPropriedadesAguardando,
            totalPropriedadesAprovadas,
            setTotalPropriedadesAprovadas,
            totalPropriedadesInativas,
            setTotalPropriedadesInativas,
            patrols,
            setPatrols,
            modal,
            setModal
        }}>
            {children}
        </AuthContext.Provider>
    );
};

