import React, { useContext } from 'react';
import { FormText, Title } from '../../../ui/styled';
import { CompanyButtonContainer } from './styled';
import Wrapper from '../Wrapper';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import { Button } from '@mui/material';

export default function ModalSample() {

  const { modal, setModal } = useContext(AuthContext)

  const close = () => {
    setModal(null)
  }

  const handleSave = () => {
    // do something
    close()
  }

  return (
    <>
      <Wrapper>
        <Title upper nomargin>Sou um modal</Title>
        <FormText>Preencha algum texto caso precise</FormText>
        <CompanyButtonContainer>
          <Button
            onClick={() => {}}
          >
            Aprovar
          </Button>
          <Button
            onClick={() => {}}
          >
            Reprovar
          </Button>
        </CompanyButtonContainer>
      </Wrapper>
    </>
  )
}
