/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/contextGlobal/authContext";
import { Wrapper, PageTitle, ContentContainer } from "./styled";
import { Read } from "../../../service/patrols";
import StatisticCard from '../../../components/Cards/StatisticCard';
import ModernTable from '../../../components/Tables/ModernTable';
import StatusButton from '../../../components/Buttons/StatusButton';
import VisitStatusButton from '../../../components/Buttons/VisitStatusButton';
import ModernMap from '../../../components/Maps/ModernMap';
import { useProperties } from '../../../context/PropertiesContext';
import React from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import BarChart from '../../../components/Charts/BarChart';
import DonutChart from '../../../components/Charts/DonutChart';
import { Info } from '@phosphor-icons/react';

const HomePage = () => {
    const {
        setPatrols,
        patrols,
        selectedProduct,
        setSelectedProduct,
        setMapCenter,
        setModal
    } = useContext(AuthContext);

    const navigation = useNavigate();
    const { fetchProperties, processedData, filteredData, totals } = useProperties();
    
    // Estado para controlar loading inicial
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            const token = localStorage.getItem('@token');
            if (!token) {
                navigation('/');
                return;
            }

            try {
                setIsLoading(true);
                await fetchProperties();
                await handlePatrols();
            } catch (error) {
                console.error('Erro na inicialização:', error);
            } finally {
                setIsLoading(false);
            }
        };

        init();
    }, [navigation]);

    // Log para debug
    useEffect(() => {
        console.log('Dados processados:', processedData);
        console.log('Dados filtrados:', filteredData);
    }, [processedData, filteredData]);

    const [chartData, setChartData] = useState({
        bar: [],
        donut: [],
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simular carregamento de dados
        setTimeout(() => {
            const mockData = {
                bar: [
                    ["Município", "Ocorrências"],
                    ["São Paulo", 1000],
                    ["Rio de Janeiro", 800],
                    ["Belo Horizonte", 600],
                    ["Salvador", 400],
                    ["Brasília", 300],
                ],
                donut: [
                    ["Município", "Ocorrências"],
                    ["São Paulo", 1000],
                    ["Rio de Janeiro", 800],
                    ["Belo Horizonte", 600],
                    ["Salvador", 400],
                    ["Brasília", 300],
                ],
            };
            setChartData(mockData);
            setLoading(false);
        }, 1500);
    }, []);

    // Colunas da tabela de propriedades
    const columns = [
        {
            field: 'name',
            headerName: 'Nome da Propriedade',
            sortable: true,
            renderCell: (rowData) => rowData.name || 'Não informado'
        },
        {
            field: 'proprietario',
            headerName: 'Nome do Proprietario',
            renderCell: (rowData) => rowData.user?.username || 'Sem proprietário',
            sortable: true
        },
        {
            field: 'property_number',
            headerName: 'Número da Propriedade',
            renderCell: (rowData) => rowData?.property_number || 'Não cadastrado',
            sortable: true
        },
        {
            field: 'area',
            headerName: 'Área',
            sortable: true,
            renderCell: (rowData) => rowData.area || 'Não informado'
        },
        {
            field: 'municipality',
            headerName: 'Endereço',
            sortable: true,
            renderCell: (rowData) => rowData.municipality || 'Não informado'
        },
        {
            field: 'line',
            headerName: 'Linha',
            sortable: true,
            renderCell: (rowData) => rowData.line || 'Não informado'
        },
        {
            field: 'patrol',
            headerName: 'Batalhão',
            renderCell: (rowData) => rowData?.patrol?.name || 'Sem Batalhão',
            sortable: true
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (rowData) => (
                <StatusButton status={rowData.status} rowData={rowData} />
            ),
            sortable: true
        },
        {
            field: 'status_visit',
            headerName: 'Visita',
            renderCell: (rowData) => (
                <VisitStatusButton status={rowData.status_visit} propertyId={rowData.id} />
            ),
            sortable: true
        },
        {
            field: 'actions',
            headerName: 'Ações',
            renderCell: (rowData) => (
                <Button
                    variant="contained"
                    color="info"
                    startIcon={<Info weight="duotone" />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setModal({
                            type: 'propertyInfo',
                            data: rowData
                        });
                    }}
                    sx={{
                        textTransform: 'none',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                            transform: 'translateY(-1px)',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                        }
                    }}
                >
                    Informações
                </Button>
            ),
            sortable: false
        }
    ];

    // Colunas da tabela de batalhões
    const patrolColumns = [
        {
            field: 'name',
            headerName: 'Nome do batalhão',
            sortable: true
        },
        {
            field: 'code',
            headerName: 'Código do batalhão',
            sortable: true
        },
        {
            field: 'license_plate',
            headerName: 'Placa',
            sortable: true,
            renderCell: (rowData) => rowData.license_plate || 'Não informado'
        }
    ];

    const handlePatrols = async () => {
        try {
            const response = await Read();
            if (response?.data) {
                const formattedPatrols = response.data.map(patrol => ({
                    id: patrol.id,
                    name: patrol.attributes.name,
                    code: patrol.attributes.code,
                    license_plate: patrol.attributes.license_plate
                }));
                setPatrols(formattedPatrols);
            }
        } catch (error) {
            console.error("Erro ao buscar batalhões:", error);
        }
    };

    const handleRowClick = (rowData) => {
        setSelectedProduct(rowData);
        if (rowData.status?.toLowerCase() === 'aguardando') {
            setModal({
                type: 'chooseAction',
                data: rowData
            });
        }
    };

    return (
        <ContentContainer>
            <PageTitle>Propriedades</PageTitle>

            <Wrapper>
                <StatisticCard title="Total" value={totals.total} />
                <StatisticCard title="Total Reprovadas" value={totals.reprovadas} />
                <StatisticCard title="Total Visitadas" value={totals.visitadas} />
                <StatisticCard title="Total Aguardando" value={totals.aguardando} />
                <StatisticCard title="Total Aprovadas" value={totals.aprovadas} />
                <StatisticCard title="Total Inativas" value={totals.inativas} />
            </Wrapper>

            <ModernMap
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                properties={filteredData?.length > 0 ? filteredData : processedData}
            />

            <ModernTable
                columns={columns}
                data={filteredData?.length > 0 ? filteredData : processedData}
                pagination={true}
                onRowClick={handleRowClick}
                rowsPerPageOptions={[5, 10, 25]}
                loading={isLoading}
            />

            <ModernTable
                columns={patrolColumns}
                data={patrols}
                pagination={true}
                rowsPerPageOptions={[5, 10, 25]}
                loading={isLoading}
            />

            <Grid container spacing={3} sx={{ mt: 0, mb: 4 }}>
                <Grid item xs={12} md={6}>
                    <Paper 
                        elevation={3} 
                        sx={{ 
                            p: 2,
                            backgroundColor: 'background.paper',
                            borderRadius: 2,
                            boxShadow: '0 2px 12px rgba(0,0,0,0.1)'
                        }}
                    >
                        <Typography 
                            variant="h6" 
                            gutterBottom
                            sx={{
                                color: 'primary.main',
                                fontWeight: 600,
                                mb: 2
                            }}
                        >
                            Ocorrências por Município
                        </Typography>
                        <BarChart
                            data={chartData.bar}
                            title="Ocorrências por Município"
                            loading={loading}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper 
                        elevation={3} 
                        sx={{ 
                            p: 2,
                            backgroundColor: 'background.paper',
                            borderRadius: 2,
                            boxShadow: '0 2px 12px rgba(0,0,0,0.1)'
                        }}
                    >
                        <Typography 
                            variant="h6" 
                            gutterBottom
                            sx={{
                                color: 'primary.main',
                                fontWeight: 600,
                                mb: 2
                            }}
                        >
                            Distribuição de Ocorrências
                        </Typography>
                        <DonutChart
                            data={chartData.donut}
                            title="Ocorrências por Município"
                            loading={loading}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </ContentContainer>
    );
};

export default HomePage;
