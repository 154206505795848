import styled from 'styled-components';

export const DrawerOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999999;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    transition: all 0.3s ease-in-out;
`;

export const DrawerContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100vh;
    background-color: white;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const DrawerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid ${props => props.theme.palette.primary.pale};

    h3 {
        margin: 0;
        color: ${props => props.theme.palette.text.primary};
    }
`;

export const DrawerContent = styled.div`
    padding: 16px;
    height: calc(100vh - 64px);
    overflow-y: auto;
`;

export const CloseButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    color: ${props => props.theme.palette.text.secondary};
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    
    &:hover {
        color: ${props => props.theme.palette.text.primary};
        transform: scale(1.1);
    }
`; 