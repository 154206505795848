import styled from 'styled-components';

export const CardContainer = styled.div`
    background: ${props => props.theme.palette.background.paper};
    border-radius: 16px;
    padding: 24px;
    min-width: 200px;
    flex: 1;
    box-shadow: 0 2px 12px ${props => props.theme.palette.shadow};
    transition: all 0.3s ease;
    border: 1px solid ${props => props.theme.palette.primary.pale};

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 24px ${props => props.theme.palette.shadow};
        border-color: ${props => props.theme.palette.primary.main};
    }

    @media (max-width: 768px) {
        min-width: 150px;
        padding: 20px;
    }
`;

export const CardContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

export const CardTitle = styled.h3`
    color: ${props => props.theme.palette.text.secondary};
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
`;

export const CardValue = styled.div`
    color: ${props => props.theme.palette.primary.dark};
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.5px;

    @media (max-width: 768px) {
        font-size: 28px;
    }
`;

export const IconContainer = styled.div`
    color: ${props => props.theme.palette.primary.main};
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    background: linear-gradient(135deg, 
        ${props => `${props.theme.palette.primary.main}10`} 0%, 
        ${props => `${props.theme.palette.primary.main}20`} 100%
    );
    border-radius: 12px;
    transition: all 0.3s ease;

    &:hover {
        background: linear-gradient(135deg, 
            ${props => `${props.theme.palette.primary.main}20`} 0%, 
            ${props => `${props.theme.palette.primary.main}30`} 100%
        );
        transform: scale(1.05);
    }
`; 