import React from 'react';
import { Chart } from "react-google-charts";
import { Box, Typography, CircularProgress } from '@mui/material';
import { ChartLine } from '@phosphor-icons/react';
import { EmptyStateContainer, LoadingContainer } from './styled';

const BarChart = ({ data, title, loading }) => {
  const options = {
    title: title,
    chartArea: { width: '50%' },
    hAxis: {
      title: 'Total',
      minValue: 0,
    },
    vAxis: {
      title: 'Município',
    },
    legend: { position: 'none' },
    colors: ['#A3A65B'], // Usando a cor primária do tema
  };

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress size={40} thickness={4} />
        <span>Carregando dados...</span>
      </LoadingContainer>
    );
  }

  if (!data || data.length <= 1) {
    return (
      <EmptyStateContainer>
        <ChartLine size={48} weight="duotone" />
        <h3>Sem dados disponíveis</h3>
        <p>Não há dados suficientes para gerar o gráfico.</p>
      </EmptyStateContainer>
    );
  }

  return (
    <Chart
      chartType="BarChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
};

export default BarChart; 