import React from 'react';
import { VisitStatusContainer } from './styled';
import { 
    CheckSquare,
    Clock
} from '@phosphor-icons/react';
import { UpdateStatus } from '../../../service/property';
import { useProperties } from '../../../context/PropertiesContext';

const getVisitStatusIcon = (status) => {
    switch (status?.toLowerCase()) {
        case 'visitada':
            return <CheckSquare size={16} weight="duotone" />;
        case 'aguardando':
        default:
            return <Clock size={16} weight="duotone" />;
    }
};

const VisitStatusButton = ({ status, propertyId }) => {
    const { fetchProperties } = useProperties();
    const normalizedStatus = status?.toLowerCase() === 'aguardando' ? 'Aguardando Visita' : 'Visitada';

    const handleClick = async (e) => {
        e.stopPropagation();
        try {
            if (status?.toLowerCase() === 'aguardando') {
                await UpdateStatus(propertyId, { data: { status_visit: 'visitada' } });
            } else if (status?.toLowerCase() === 'visitada') {
                await UpdateStatus(propertyId, { data: { status_visit: 'aguardando' } });
            }
            await fetchProperties();
        } catch (error) {
            console.error('Erro ao atualizar status:', error);
        }
    };

    return (
        <VisitStatusContainer status={status} onClick={handleClick}>
            {getVisitStatusIcon(status)}
            {normalizedStatus}
        </VisitStatusContainer>
    );
};

export default VisitStatusButton; 