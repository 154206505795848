import React, { useState, useEffect } from 'react';
import {
    TableWrapper,
    StyledTable,
    TableHeader,
    HeaderRow,
    HeaderCell,
    BodyRow,
    BodyCell,
    StyledSortLabel,
    PaginationContainer,
    StyledPagination,
    EmptyStateContainer,
    LoadingContainer
} from './styled';
import { TableBody, CircularProgress, Box } from '@mui/material';
import { Database } from '@phosphor-icons/react';

const ModernTable = ({
    columns,
    data = [],
    pagination = true,
    rowsPerPageOptions = [5, 10, 25],
    onRowClick = null,
    loading = false,
}) => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [orderBy, setOrderBy] = useState('');
    const [orderDirection, setOrderDirection] = useState('asc');

    const handleSort = (column) => {
        const columnDef = columns.find((col) => col.field === column);

        if (columnDef?.sortable) {
            const isAsc = orderBy === column && orderDirection === 'asc';
            setOrderDirection(isAsc ? 'desc' : 'asc');
            setOrderBy(column);
        }
    };

    const getComparator = (a, b, column) => {
        let valueA = a[column];
        let valueB = b[column];

        const columnDef = columns.find((col) => col.field === column);

        if (columnDef && columnDef.renderCell) {
            valueA = a[columnDef.field];
            valueB = b[columnDef.field];
        }

        if (typeof valueA === 'string') {
            valueA = valueA.toLowerCase();
            valueB = valueB.toLowerCase();
        }

        if (valueA < valueB) return orderDirection === 'asc' ? -1 : 1;
        if (valueA > valueB) return orderDirection === 'asc' ? 1 : -1;
        return 0;
    };

    const sortedData = [...data].sort((a, b) => getComparator(a, b, orderBy));

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const displayedRows = sortedData.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
    );

    if (loading) {
        return (
            <TableWrapper>
                <LoadingContainer>
                    <CircularProgress size={40} thickness={4} />
                    <span>Carregando dados...</span>
                </LoadingContainer>
            </TableWrapper>
        );
    }

    if (!data.length) {
        return (
            <TableWrapper>
                <EmptyStateContainer>
                    <Database size={48} weight="duotone" />
                    <h3>Nenhum registro encontrado</h3>
                    <p>Não existem dados disponíveis para exibição no momento.</p>
                </EmptyStateContainer>
            </TableWrapper>
        );
    }

    return (
        <TableWrapper>
            <StyledTable>
                <TableHeader>
                    <HeaderRow>
                        {columns.map((column) => (
                            <HeaderCell key={column.field}>
                                {column.sortable ? (
                                    <StyledSortLabel
                                        active={orderBy === column.field}
                                        direction={orderBy === column.field ? orderDirection : 'asc'}
                                        onClick={() => handleSort(column.field)}
                                    >
                                        {column.headerName}
                                    </StyledSortLabel>
                                ) : (
                                    column.headerName
                                )}
                            </HeaderCell>
                        ))}
                    </HeaderRow>
                </TableHeader>
                <TableBody>
                    {displayedRows.map((row, index) => (
                        <BodyRow
                            key={index}
                            onClick={() => onRowClick && onRowClick(row)}
                            hover={!!onRowClick}
                        >
                            {columns.map((column) => (
                                <BodyCell key={column.field}>
                                    {column.renderCell ? column.renderCell(row) : row[column.field]}
                                </BodyCell>
                            ))}
                        </BodyRow>
                    ))}
                </TableBody>
            </StyledTable>
            {pagination && data.length > 0 && (
                <PaginationContainer>
                    <StyledPagination
                        count={Math.ceil(data.length / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </PaginationContainer>
            )}
        </TableWrapper>
    );
};

export default ModernTable; 