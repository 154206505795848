import React from 'react';
import { CloseContainer, CompanyDataContainer, Title, WrapperTitleAndCloseIcon } from './styled';
import { Icon } from '../../../ui/styled';
import { useContext } from 'react';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import styled from 'styled-components';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.palette.colors.shadow};
    z-index: 1001;
    cursor: auto;
`;

const ModalContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
`;

const ModalContent = styled.div`
    gap: 16px;
    overflow-y: auto; 
    max-height: 90vh;
    box-sizing: border-box;
    animation: slideDown 0.3s ease-out;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: ${props => props.width || '640px'};  
    background: ${props => props.theme.palette.background.paper};
    padding: 24px 40px 40px 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px ${props => props.theme.palette.colors.shadow};

    @keyframes slideDown {
        from {
            opacity: 0;
            transform: translateY(-100%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (max-width: 1000px) {
        padding: 20px;
        max-width: 100%;
        min-width: 100%;
    }

    @media (max-width: 480px) {
        padding: 15px;
        gap: 15px;
    }
`;

export default function Wrapper({ children, title, width }) {
    const { setModal } = useContext(AuthContext);

    const close = () => {
        setModal(null);
    };

    const handleClose = (e) => {
        const mc = document.getElementById('modal-content');
        if (mc && !mc.contains(e.target)) {
            close();
        }
    };

    return (
        <Overlay onClick={handleClose}>
            <ModalContainer>
                <ModalContent width={width} id="modal-content">
                    <CompanyDataContainer>
                        <WrapperTitleAndCloseIcon>
                            <Title>{title}</Title>
                            <CloseContainer>
                                <Icon icon={'close-big'} pointer={true} onClick={close} />
                            </CloseContainer>
                        </WrapperTitleAndCloseIcon>
                        {children}
                    </CompanyDataContainer>
                </ModalContent>
            </ModalContainer>
        </Overlay>
    );
}
