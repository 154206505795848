import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            light: '#D5D96C',    // Verde limão
            main: '#A3A65B',     // Verde oliva
            dark: '#51594C',     // Verde escuro
            pale: '#D9D5A0',     // Bege claro
            contrastText: '#fff',
        },
        text: {
            primary: '#51594C',    // Verde escuro para textos principais
            secondary: '#A3A65B',  // Verde oliva para textos secundários
            disabled: '#D9D5A0',   // Bege claro para textos desabilitados
            light: '#666666',    // Adicionado para textos mais claros
        },
        background: {
            default: '#FFFFFF',
            paper: '#FFFFFF',
            input: '#F8F9FA',
        },
        colors: {            // Adicionando seção de cores específicas
            black: '#0D0D0D',
            lightblack: '#666666',
            white: '#FFFFFF',
            border: '#E0E0E0',
            shadow: 'rgba(0, 0, 0, 0.1)'
        },
        input: {
            label: '#51594C',
            text: '#0D0D0D',
            background: '#F5F5F5',
            border: {
                default: '#D9D5A0',
                focus: '#A3A65B',
                error: '#D32F2F',
            }
        },
        map: {
            marker: {
                primary: '#A3A65B',
                glow: 'rgba(163, 166, 91, 0.2)',
                shadow: 'rgba(13, 13, 13, 0.2)',
            }
        },
        button: {
            primary: '#A3A65B',
            hover: '#51594C',
            active: '#51594C',
            disabled: '#D9D5A0',
        },
        common: {
            black: '#0D0D0D',
            white: '#ffffff',
        },
        shadow: 'rgba(13, 13, 13, 0.08)',
    },
});  